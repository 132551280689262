<template>
  <v-container id="services" class="ma-auto" fluid>
    <v-row class="pa-0 ma-0 main">
      <v-col cols="12" class="ma-auto pa-0">
        <v-row>
          <v-col cols="12" md="10" lg="8" class="ma-auto my-12 py-12" data-aos="slide-right">
            <h1 class="text-h2 text--secondary">{{$t("itservices")}}</h1>
            <div class="text-h4 text--secondary font-weight-light my-8">{{$t("servicesdesc")}}</div>
            <v-btn class="custom-accent--text" @click="scrollToId('contact')" dark depressed rounded outlined>{{$t("requestaquote")}}<v-icon class="ml-2">mdi-arrow-right</v-icon></v-btn>
          </v-col>
        </v-row>
        <v-row class="custom-accent white--text">
          <v-col cols="12" md="10" lg="8" class="ma-auto py-12 text-center">
            <v-row class="pa-0 ma-0" height="auto">
              <v-col cols="12" class="text-center mb-5" data-aos="slide-bottom">
                <span class="text-h3">{{$t("ouritservices")}}</span>
              </v-col>
              <v-col cols="12" class="mb-4" data-aos="zoom-in">
                <v-divider class="mx-2 white"></v-divider>
              </v-col>
            </v-row>
            <v-row class="ma-0 pa-0" v-if="service_loading && !services.length"> 
              <v-col cols="12" v-for="i in 6" :key="i" class="ma-auto" sm="6" md="4" data-aos="fade-in">
                <v-skeleton-loader
                  type="image"
                ></v-skeleton-loader>
                <v-skeleton-loader class="my-2"
                  type="heading"
                ></v-skeleton-loader>
                <v-skeleton-loader
                  type="sentences"
                ></v-skeleton-loader>
              </v-col>
            </v-row>
            <v-row class="ma-0 pa-0" v-else> 
              <v-col cols="12" v-for="service in services" :key="service.id" sm="6" md="4">
                <v-card height="100%" class="values-cards transparent elevation-0" data-aos="zoom-in">
                  <v-card-title>
                    <v-avatar
                      class="ma-auto"
                      style="border: 1px solid white !important;"
                      size="88">
                      <v-icon class="white--text" x-large>
                        {{service.icon}}
                      </v-icon>
                    </v-avatar>
                  </v-card-title>
                  <v-card-title>
                    <span v-line-clamp="1" class="ma-auto white--text">{{$i18n.locale=='en'?service.titleEN:service.titleFR}}</span>
                  </v-card-title>
                  <v-card-text class="white--text font-weight-light">
                    <expandable-text-line class="overflow-hidden">
                      <span v-html="$i18n.locale=='en'?service.descriptionEN:service.descriptionFR"></span>
                    </expandable-text-line>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="text-center text-md-left">
          <v-col cols="12" md="12" lg="10" class="ma-auto my-6 py-12">              
            <div class="text-h4 text-uppercase text-center text--secondary" data-aos="fade">{{$t("seewhatsnew")}}</div>
            <v-row class="ma-0 pa-0" v-if="blog_loading && !blogs.length"> 
              <v-col class="ma-auto pa-12" cols="12" sm="6" md="4" lg="3" data-aos="fade-down">
                <v-skeleton-loader
                  type="image"
                ></v-skeleton-loader>
                <v-skeleton-loader class="my-2"
                  type="heading"
                ></v-skeleton-loader>
                <v-skeleton-loader
                  type="sentences,button"
                ></v-skeleton-loader>
              </v-col>
              <v-col class="ma-auto pa-12 d-none d-sm-block d-md-block d-lg-block" cols="12" sm="6" md="4" lg="3" data-aos="fade-down">
                <v-skeleton-loader
                  type="image"
                ></v-skeleton-loader>
                <v-skeleton-loader class="my-2"
                  type="heading"
                ></v-skeleton-loader>
                <v-skeleton-loader
                  type="sentences,button"
                ></v-skeleton-loader>
              </v-col>
              <v-col class="ma-auto pa-12 d-none d-sm-none d-md-block d-lg-block" cols="12" sm="6" md="4" lg="3" data-aos="fade-down">
                <v-skeleton-loader
                  type="image"
                ></v-skeleton-loader>
                <v-skeleton-loader class="my-2"
                  type="heading"
                ></v-skeleton-loader>
                <v-skeleton-loader
                  type="sentences,button"
                ></v-skeleton-loader>
              </v-col>
              <v-col class="ma-auto pa-12 d-none d-sm-none d-md-none d-lg-block" cols="12" sm="6" md="4" lg="3" data-aos="fade-down">
                <v-skeleton-loader
                  type="image"
                ></v-skeleton-loader>
                <v-skeleton-loader class="my-2"
                  type="heading"
                ></v-skeleton-loader>
                <v-skeleton-loader
                  type="sentences,button"
                ></v-skeleton-loader>
              </v-col>
            </v-row>
            <div class="ma-0 pa-0" v-else>
              <div class="custom-off-white" data-aos="flip-up">
                <swiper class="swiper pa-12" :options="swiperOption">
                  <swiper-slide v-for="item in blogs" :key="item.id">
                    <v-card class="mx-auto elevation-0 rounded-xl" outlined>
                      <v-img v-if="item.image" :src="require('../../assets/'+item.image)" :alt="item.alt" height="175px"></v-img>
                      <v-img v-else :src="require('../../assets/images/newsevents/events-default-bg.png')" :alt="$t('eventsdefaultbgalt')" height="175px"></v-img>
                      <v-card-title>
                        <span v-line-clamp="1">{{$i18n.locale=='en'?item.titleEN:item.titleFR}}</span>
                      </v-card-title>
                      <v-card-subtitle class="blog-values-cards">
                        <span class="v-html-line-clamp-2 custom-secondary--text" v-html="$i18n.locale=='en'?item.descriptionEN:item.descriptionFR"></span>
                      </v-card-subtitle>
                      <v-card-actions>
                        <v-btn color="custom-accent " text @click="$navigateToPath('/blog/news-events/ /'+item.id)">
                          {{$t("viewmore")}}
                        </v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </swiper-slide>
                  <div class="swiper-pagination" slot="pagination"></div>
                  <div class="swiper-button-prev custom-accent--text" slot="button-prev"></div>
                  <div class="swiper-button-next custom-accent--text" slot="button-next"></div>
                </swiper>
              </div>
            </div>
            <div class="px-12">
              <v-btn class="custom-accent" @click="$navigateToPath('/blog/news-events')" dark depressed rounded>{{$t("visitourblog")}}<v-icon class="ml-2">mdi-arrow-right</v-icon></v-btn>
            </div>
          </v-col>
        </v-row>
        <v-row data-aos="fade-down" class="custom-accent white--text" id="contact">
          <v-col cols="12" sm="10" md="7" lg="5" class="text-center ma-auto pa-12">
            <div class="text-h4 text-uppercase">{{$t("servicesknowmore")}}</div>
            <v-btn class="my-8" @click="$navigateToPath('/contact')" dark depressed rounded>{{$t("leaveusamessage")}}<v-icon class="ml-2">mdi-arrow-right</v-icon></v-btn>
            <contact-us-form data-aos="fade-right" :isDark="true" :type="'services'"></contact-us-form>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import contactusform from '../../components/ContactUsForm.vue';
import ExpandableTextLine from 'vue-expandable-text-line';
import { mapState,mapActions,mapGetters} from "vuex";
export default {
  name: "Services",

  data() {
    return {
      swiperOption: {
        slidesPerView: 6,
        spaceBetween: 60,
        notNextTick: true,
        initialSlide: 0,
        autoplay: {
            delay: 3000,
            pauseOnMouseEnter: true,
            disableOnInteraction: false,
        },
        speed: 800,
        grabCursor: true,
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable:true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        breakpoints: {
          1524: {
            slidesPerView: 5,
            spaceBetween: 50
          },
          1224: {
            slidesPerView: 4,
            spaceBetween: 40
          },
          904: {
            slidesPerView: 3,
            spaceBetween: 40
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 10
          }
        }
      }
    };
  },
  components: {
    'contact-us-form':contactusform,
    'expandable-text-line':ExpandableTextLine
  },
  async created() {
    await this.retrieveServices();
    await this.retrieveBlogs();
  },
  computed: {
    ...mapGetters('service', {
      services:'getServices'
    }),
    ...mapState("service", {
      service_loading: "loading"
    }),
    ...mapGetters('blog', {
      blogs:'getBlogs'
    }),
    ...mapState("blog", {
      blog_loading: "loading"
    }),
  },
  watch: {
  },
  methods: {
    scrollToId(id){
      let element = document.getElementById(id);
      element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    },

    ...mapActions("service", ["addService","retrieveServices"]),
    ...mapActions("blog", ["addBlog","retrieveBlogs"]),
  },
};
</script>
<style scoped>
@import url('../../assets/styles/services.css');
</style>